import React, { Component} from 'react';
import { Container, Spinner, Image, Button, Card, Breadcrumb } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import {API} from '../../variables/variables';
import { Helmet } from 'react-helmet';
import { RiTimeLine } from "react-icons/ri";

class PetQuickStartGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pet: this.props.pet,
            type: this.props.type,
            data: {},
            loading: true
        }
    }

    async componentDidMount() {
        const types_reponse = await fetch(API + '/pet?name=' + this.props.pet, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(types_reponse.ok){
            const data = await types_reponse.json();
            this.setState({data : data.data});
        }
        else{

        }
        await fetch(API + '/views?type=pet&name=' + this.props.pet, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });

        this.setState({loading : false});
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.pet} Care - Quick Start Guide</title>
                    <link rel="canonical" href={"https://petquickstart.com/pets/" + this.state.type + "/" + this.state.pet} />
                </Helmet>
                <NavigationBar/>
                <Container>
                    <section>
                        <header className="hero" role="banner">
                            <Breadcrumb className="breadcrumb-bar">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href='/pets'>
                                    Pet Types Gallery
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href={'/pets/' + this.state.type}>
                                    {this.state.type} Gallery
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {this.state.pet} Quick Start Guide
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div className="clear-div">
                            </div>
                            <Button variant="dark" href={'/pets/' + this.state.type}>Back</Button>
                            <br></br>
                            <br></br>
                            <div className="hero-content-guide">
                                {this.state.loading?
                                    <div></div>
                                    :
                                    <div className="center">
                                        <Image src={this.state.data.picture_url} rounded alt={this.state.data.name} className="guide-img"/>
                                    </div>
                                }
                                <br></br>
                                <br></br>
                                <h1 className="center">{this.state.pet} Care - Quick Start Guide</h1>
                                {this.state.loading?
                                    <>
                                    </>:
                                    <p><b>Views: </b>{this.state.data.views}</p>
                                }
                                <p><b>3 - 5 minute read <RiTimeLine /></b></p>
                                <p><b>Updated on</b>: {this.state.data.updatedAt}</p>
                                <hr></hr>
                            </div>
                            <div className="clear-div"></div>
                        </header>
                    </section>
                    <div className="clear-div">
                    </div>
                    <section>
                        <br></br>
                        {this.state.loading?
                            <div className="center">
                                <Spinner className="loading-spinner"/>
                            </div>
                            :
                            <div className="guide">
                                <div className="guide-left">
                                    <section>
                                        <Card>
                                            <Card.Header className="requirement-header"><h2>Difficulty</h2></Card.Header>
                                            <Card.Body>
                                                <ul className="req-list">
                                                    {this.state.data.difficulty_rating === "Very Easy" && <li><span style={{color:"green"}}>{this.state.data.difficulty_rating}</span></li>}
                                                    {this.state.data.difficulty_rating === "Easy" && <li><span style={{color:"green"}}>{this.state.data.difficulty_rating}</span></li>}
                                                    {this.state.data.difficulty_rating === "Medium" && <li><span style={{color:"#B8860B"}}>{this.state.data.difficulty_rating}</span></li>}
                                                    {this.state.data.difficulty_rating === "Hard" && <li><span style={{color:"red"}}>{this.state.data.difficulty_rating}</span></li>}
                                                </ul>
                                            </Card.Body>
                                        </Card>
                                    </section>
                                    <section>
                                        <Card>
                                            <Card.Header className="requirement-header"><h2>Diet</h2></Card.Header>
                                            <Card.Body><ol className="req-list">
                                            {this.state.data.requirements.diet.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                            </ol></Card.Body>
                                        </Card>
                                    </section>
                                    <section>
                                        <Card>
                                            <Card.Header className="requirement-header"><h2>Exercise</h2></Card.Header>
                                            <Card.Body><ol className="req-list">
                                            {this.state.data.requirements.exercise.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                        </ol></Card.Body>
                                        </Card>
                                    </section>
                                    <section>
                                        <Card>
                                            <Card.Header className="requirement-header"><h2>Training</h2></Card.Header>
                                            <Card.Body>{this.state.data.requirements.training? 
                                            <div>
                                                <ol className="req-list">
                                                    {this.state.data.requirements.training.map((req, index) =>
                                                        <li key={index}>{req}</li>
                                                    )}
                                                </ol>
                                            </div>
                                            :
                                            <ul className="req-list">
                                                <li>No training requirements.</li>
                                            </ul>
                                        }</Card.Body>
                                        </Card>
                                    </section>
                                    
                                </div>
                                <div className="guide-right">
                                    <section>
                                        <Card>
                                            <Card.Header className="requirement-header"><h2>Habitat</h2></Card.Header>
                                            <Card.Body>
                                                <ol className="req-list">
                                                    {this.state.data.requirements.habitat.map((req, index) =>
                                                        <li key={index}>{req}</li>
                                                    )}
                                                </ol>
                                            </Card.Body>
                                        </Card>
                                    </section>
                                    <section>
                                        <Card>
                                            <Card.Header className="requirement-header"><h2>Hygiene</h2></Card.Header>
                                            <Card.Body>
                                            <ol className="req-list">
                                                {this.state.data.requirements.hygiene.map((req, index) =>
                                                    <li key={index}>{req}</li>
                                                )}
                                            </ol>
                                            </Card.Body>
                                        </Card>
                                    </section>
                                    <section>
                                        <Card>
                                            <Card.Header className="requirement-header"><h2>Health / Vet</h2></Card.Header>
                                            <Card.Body><ol className="req-list">
                                            {this.state.data.requirements.vet.requirements.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                        </ol></Card.Body>
                                        </Card>
                                    </section>
                                </div>
                            </div>
                        }
                    </section>
                    <br></br>
                </Container>
            </div>
        )
    }
}

export default PetQuickStartGuide;