import React, { Component} from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import { Helmet } from 'react-helmet';

class HomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <Helmet>
                </Helmet>
                <NavigationBar/>
                <Container>
                    <header className="hero" role="banner">
                        <div className="hero-content">
                            <h1 className="title-text">Quick & Easy Pet Care Info</h1>
                            <p>Explore hundreds of pet care guides, articles, and products all in one place. Get advice for optimal pet care, from feeding to grooming, tailored to every pet owner's needs.</p>
                            <div className="center">
                                <Button className="any-button" size="lg" href="/pets">Start Exploring</Button>
                            </div>
                        </div>
                        <br></br>
                        <div className="hero-image">
                            <Image src="https://glb.petquickstart.com/hero-img.webp" rounded alt="Happy pets" className="happy-pet-img"/>
                        </div>
                    </header>
                    <div className="clear-div"></div>
                </Container>
            </div>
        )
    }
}

export default HomeScreen;