import React, { Component} from 'react';
import { Button, Container } from 'react-bootstrap';

class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <Container>
                    <div className="float-left">
                        <h1>404 Oops it appears this page doesn't exist!</h1>
                        <br></br>
                        <Button className="rounded" href='/' variant="primary" size="lg"><b>Click to Return</b></Button>
                    </div>
                </Container>
            </div>
        )
    }
}

export default PageNotFound;