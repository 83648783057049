import React, { Component} from 'react';
import { Container, Image } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import { Helmet } from 'react-helmet';

class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Pet Quick Start Shop</title>
                    <link rel="canonical" href="https://petquickstart.com/shop" />
                </Helmet>
                <NavigationBar/>
                <Container>
                    <header className="hero" role="banner">
                        <div className="hero-content">
                            <h1 className="title-text">The Shop Is Coming Soon!</h1>
                        </div>
                        <br></br>
                        <div className="hero-image">
                            <Image src="https://glb.petquickstart.com/hero-img.webp" rounded alt="Happy pets" className="happy-pet-img"/>
                        </div>
                    </header>
                </Container>
            </div>
        )
    }
}

export default Shop;