import React, { Component} from 'react';
import { Container, Image } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import { Helmet } from 'react-helmet';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Pet Quick Start About</title>
                    <link rel="canonical" href="https://petquickstart.com/about" />
                </Helmet>
                <NavigationBar/>
                <Container>
                    <header className="hero" role="banner">
                        <div className="hero-content">
                        <section className="about-section">
                            <div className="container">
                                <h1 className="section-title title-text">About</h1>
                                <p className="about-description">
                                Welcome to Pet Quick Start, your ultimate destination for all things pet care! We understand that bringing a new pet into your life can be exciting but overwhelming. That's why we've created a one-stop platform where you can learn everything you need to know about your new furry, feathery, or scaly friend and conveniently find all the necessary items for their care in one place.
                                </p>
                                <p className="about-description">
                                Whether you're a first-time pet owner or a seasoned pro, Pet Quick Start is here to support you on your journey. Our comprehensive guides cover a wide range of pets, from dogs and cats to birds, reptiles, and small animals. We provide expert advice on pet nutrition, grooming, training, and health care to ensure that you and your pet enjoy a happy and fulfilling life together.
                                </p>
                                <p className="about-description">
                                In addition to valuable information, Pet Quick Start also offers a curated selection of high-quality pet products sourced from trusted brands. From premium pet food and toys to essential grooming supplies and healthcare items, we've got everything you need to keep your pet healthy, happy, and pampered.
                                </p>
                                <p className="about-description">
                                At Pet Quick Start, we're passionate about pets, and we're dedicated to helping you be the best pet parent you can be. Whether you're adopting a rescue pup, welcoming a cuddly kitten into your home, or adding a colorful fish to your aquarium, let Pet Quick Start be your guide to pet care excellence.
                                </p>
                            </div>
                        </section>
                        </div>
                        <br></br>
                        <div className="hero-image">
                        <Image src="https://glb.petquickstart.com/hero-img.webp" rounded alt="Happy pets" className="happy-pet-img"/>
                        </div>
                    </header>
                </Container>
            </div>
        )
    }
}

export default About;