import React, { Component} from 'react';
import { Container, Form, Spinner, Image, Button, Breadcrumb } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import {API} from '../../variables/variables';
import { Helmet } from 'react-helmet';
import { FaSquareArrowUpRight } from "react-icons/fa6";

class SpecificPetTypeGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            petType : "",
            gallery: [],
            searchGallery: [],
            page: 1,
            maxPages: 1,
            loading: true,
            meta: ""
        }
        this.search = this.search.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.search = this.search.bind(this);
    }

    async componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        let page = searchParams.get('page');
        if(page == null){
            page = 1;
        }
        else{
            this.setState({ page: page });
        }
        const types_reponse = await fetch(API + '/pets?type=' + this.props.type + "&page=" + page, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(types_reponse.ok){
            const data = await types_reponse.json();
            this.setState({gallery : data.data});
            this.setState({searchGallery : data.data});
            this.setState({meta : data.meta});
            this.setState({maxPages : data.meta.pages});
        }
        else{

        }
        this.setState({loading : false});
    }

    search(e) {
        e.preventDefault();
        window.location.href="/pets/search/" + document.getElementById('in-line-search-value').value;
    }

    nextPage() {
        const searchParams = new URLSearchParams(window.location.search);
        let next_page = parseInt(this.state.page)+1;
        searchParams.set('page', next_page);
        window.location.search = searchParams.toString();
    }

    previousPage() {
        const searchParams = new URLSearchParams(window.location.search);
        let next_page = parseInt(this.state.page)-1;
        searchParams.set('page', next_page);
        window.location.search = searchParams.toString();
    }

    searchChange(value) {
        this.setState({petType :value}, () => {
                this.search();
            });
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.type} Gallery - Pet Quick Start</title>
                    <meta name="description" content={this.state.meta.meta_tag}/>
                    {this.props.type !== undefined && <link rel="canonical" href={"https://petquickstart.com/pets/" + this.props.type} />}
                </Helmet>
                <NavigationBar/>
                <Container>
                    <section>
                        <header className="hero" role="banner">
                            <Breadcrumb className="breadcrumb-bar">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href='/pets'>
                                    Pet Types Gallery
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {this.state.type} Gallery
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div className="hero-content">
                                <h1 className="title-text">{this.state.type} Gallery</h1>
                                <Button variant="dark" href="/pets">Back</Button>
                            </div>
                            <div className="clear-div">
                            </div>
                                <h2 className="center">Find Your Perfect Companion</h2>
                            <br></br>
                        </header>
                    </section>
                    <div className="clear-div">
                    </div>
                    <section>
                        <div className="searchBar">
                            <Form inline className="nav-search" onSubmit={e => this.search(e)}>
                                <Form.Control type="text" placeholder="Search" className="mr-sm-2" id="in-line-search-value"/>
                                <Button variant="outline-primary" className="search-button-nav" type="submit">Search</Button>
                            </Form>
                        </div>
                    </section>
                    <section>
                        <br></br>
                        {this.state.loading?
                            <div className="center">
                                <Spinner className="loading-spinner"/>
                            </div>
                            :
                            <div>
                                <div className="pet-gallery">
                                    {this.state.searchGallery.map((pet, index) =>
                                        <div key={index} className="pet-card">
                                            <a href={'/pets/' + pet.type + "/" + pet.name}>
                                                <div style={{width:"300px"}}>
                                                    <Image src={pet.picture_url} rounded alt={pet.name} style={{width:"300px"}} loading="lazy"/>
                                                    <div>
                                                        <div>
                                                            <div style={{width:"280px"}} className="float-left">
                                                                <h3>{pet.name}</h3>
                                                                <p>
                                                                    <b>Difficulty:</b> {pet.difficulty_rating}
                                                                </p>
                                                            </div>
                                                            <div className="float-right">
                                                                <FaSquareArrowUpRight className="title-text"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="pages">
                                    {(parseInt(this.state.page) === 1)?
                                        <Button className="any-button page-item page-button" disabled={true}>Previous</Button>:
                                        <Button className="any-button page-item page-button" onClick={this.previousPage}>Previous</Button>}
                                    <p className="page-item page-number">Page: {this.state.page}/{this.state.maxPages}</p>
                                    {parseInt(this.state.page) !== this.state.maxPages?
                                        <Button className="any-button page-item page-button" onClick={this.nextPage}>Next</Button>:
                                        <Button className="any-button page-item page-button" disabled={true}>Next</Button>
                                    }
                                </div>
                            </div>
                        }
                    </section>
                    <br></br>
                </Container>
            </div>
        )
    }
}

export default SpecificPetTypeGallery;