import React, { Component } from 'react';
import { Container, Breadcrumb, Button, Spinner, Image, Form} from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import { API } from '../../variables/variables';
import { FaSquareArrowUpRight } from "react-icons/fa6";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_name : this.props.search_name,
            loading: true
        }
        this.search = this.search.bind(this);
    }

    async componentDidMount() {
        const search_reponse = await fetch(API + '/search?query=' + this.props.search_name, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(search_reponse.ok){
            const data = await search_reponse.json();
            this.setState({data : data.data});
        }
        else{
        
        }
        this.setState({loading : false});
    }

    search(e) {
        e.preventDefault();
        window.location.href="/pets/search/" + document.getElementById('in-line-search-value').value;
    }

    render() {
        return (
            <div>
                <NavigationBar />
                <Container>
                    <section>
                            <header className="hero" role="banner">
                                <Breadcrumb className="breadcrumb-bar">
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href='/pets'>
                                        Pet Types Gallery
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Search
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <div className="hero-content">
                                    <h1>Search: {this.state.search_name}</h1>
                                    <Button variant="dark" href="/pets">Back</Button>
                                </div>
                                <div className="clear-div">
                                </div>
                                    <h2 className="center">Find Your Perfect Companion</h2>
                                <br></br>
                            </header>
                        </section>
                        <div className="clear-div"></div>
                        <section>
                            <div className="searchBar">
                                <Form inline className="nav-search" onSubmit={e => this.search(e)}>
                                    <Form.Control type="text" placeholder="Search" className="mr-sm-2" id="in-line-search-value"/>
                                    <Button variant="outline-primary" className="search-button-nav" type="submit">Search</Button>
                                </Form>
                            </div>
                        </section>
                        <br></br>
                        <div>
                            {this.state.loading?
                                <div className="center">
                                    <Spinner className="loading-spinner"/>
                                </div>
                                :
                                <>
                                {this.state.data.length === 0? 
                                    <div className="center">
                                        <h3>. . . We're sorry, we couldn't find anything using that search!</h3>
                                    </div>
                                    :
                                    <div className="pet-gallery">
                                        {this.state.data.map((pet, index) =>
                                            <div key={index} className="pet-card">
                                            <a href={'/pets/' + pet.type + "/" + pet.name}>
                                                <div style={{width:"300px"}}>
                                                    <Image src={pet.picture_url} rounded alt={pet.name} style={{width:"300px"}}/>
                                                    <div>
                                                        <div>
                                                            <div style={{width:"280px"}} className="float-left">
                                                                <h3>{pet.name}</h3>
                                                                <p>
                                                                    <b>Difficulty:</b> {pet.difficulty_rating}
                                                                </p>
                                                            </div>
                                                            <div className="float-right">
                                                                <FaSquareArrowUpRight />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        )}
                                    </div>
                                }
                                </>
                            }
                        </div>
                        <p></p>
                </Container>
            </div>
        )
    }
}

export default Search;