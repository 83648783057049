import React, { Component} from 'react';
import { Container, Form, Spinner, Image, Button, Breadcrumb } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import {API} from '../../variables/variables';
import { Helmet } from 'react-helmet';
import {marked} from 'marked';

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            article: {},
            previewHtml: "",
            loading: true
        }
       
    }

    async componentDidMount() {
        const types_reponse = await fetch(API + '/article?name=' + this.props.name, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(types_reponse.ok){
            const data = await types_reponse.json();
            this.setState({article : data.data});
            this.renderMarkdown(data.data.article_content);
        }
        else{

        }
        await fetch(API + '/views?type=article&name=' + this.props.name, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });

        this.setState({loading : false});
        this.setState({loading : false});
    }

    renderMarkdown(article_content) {
        this.setState({previewHtml : marked(article_content, { sanitize: true })})
    }

    render() {
        
        return (
            <div>
                <Helmet>
                    <title>{this.state.name}</title>
                    <link rel="canonical" href={"https://petquickstart.com/articles/" + this.state.name} />
                </Helmet>
                <NavigationBar/>
                <Container>
                        {this.state.loading?
                            <div className="center">
                                <Spinner className="loading-spinner"/>
                            </div>
                            :
                            <div>
                                <section>
                                    <header className="hero" role="banner">
                                        <Breadcrumb className="breadcrumb-bar">
                                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/articles">
                                                Articles
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {this.props.name}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </header>
                                </section>
                                <div className="clear-div">
                                </div>
                                <Button variant="dark" href="/articles">Back</Button>
                                <section className="article-content">
                                    <div>
                                        <h1>{this.state.article.article_name}</h1>
                                        <p><b>Views: </b> {this.state.article.views}</p>
                                        <hr></hr>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.previewHtml }}/>
                                    </div>
                                </section>
                           </div>
                        }
                </Container>
            </div>
        )
    }
}

export default Article;